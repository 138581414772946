<template>
  <div>
    <loading
      :active="isLoading || isSendingRaas"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <!-- <div class="col-4">
        <span class="text-gray-800 fs-6 text-start fw-bold ms-5 me-4"
          >Invia:</span
        >
        <div class="btn-group">
          <button class="btn btn-light-primary" @click="sendRaasSelezionati">
            Selezionati
          </button>
          <button
            class="btn btn-light-primary"
            style="border-left: 1px solid #009ef7"
            @click="sendRaasTutti"
          >
            Tutti
          </button>
        </div>
      </div> -->
      <div class="col-6 fs-5 text-center align-self-center">
        <!-- <button
          type="button"
          class="badge bg-secondary rounded blueFit me-4 mb-1"
        >
          Allinea con modifiche
        </button> -->
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="results && results.length === 0 && loaded"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :tableHeader2="tableHeader2"
        :table-data="results"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsFormazione"
        @current-change="setCurrentPageFormazione"
      >
        <!-- @checkboxClicked="
          checkAll = !checkAll;
          results.forEach((element) => {
            if (element.id_stato != 2) element.selected = checkAll;
          });
        "
        :checkedAll="checkAll"-->
        <!-- <template v-slot:cell-checkbox="{ row: data }">
          <input
            v-if="data.id_stato != 2"
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
            :value="data.id_societa"
          />
        </template> -->
        <template v-slot:cell-codice="{ row: data }"
          ><div class="">
            {{ data.codice }}
          </div>
        </template>
        <template v-slot:cell-livelloCompetizione="{ row: data }"
          ><div class="">
            {{ data.livelloCompetizione }}
          </div>
        </template>
        <template v-slot:cell-denominazione="{ row: data }"
          ><div class="tab-long">
            {{ data.denominazione }}
          </div>
        </template>
        <template v-slot:cell-luogo="{ row: data }"
          ><div class="">
            {{ data.svolgimento_comune }} ({{ data.svolgimento_provincia }})
          </div>
        </template>
        <template v-slot:cell-data_inizio="{ row: data }">
          <div class="text-center">
            {{ useFormatDate(data.data_inizio) }}
          </div>
        </template>
        <template v-slot:cell-data_fine="{ row: data }">
          <div class="text-center">
            {{ useFormatDate(data.data_fine) }}
          </div>
        </template>
        <template v-slot:cell-n_partecipanti="{ row: data }">
          <div class="text-center">
            {{ data.n_partecipanti }}
          </div>
        </template>
        <template v-slot:cell-partecipanti="{ row: data, index: idx }">
          <div class="accordion-item">
            <div class="accordion-header" :id="`TorneiRaas_int_${data.codice}`">
              <button
                class="accordion-button m-0 p-3 collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#TorneiRaas_panel_${data.codice}`"
                aria-expanded="falde"
                :aria-controls="`#TorneiRaas_panel_${data.codice}`"
                :style="idx % 2 == 0 ? 'background-color: #f8fafb' : ''"
              >
                Partecipanti:
              </button>
            </div>
            <div
              :id="`TorneiRaas_panel_${data.codice}`"
              class="accordion-collapse collapse"
              :aria-labelledby="`TorneiRaas_int_${data.codice}`"
              :style="idx % 2 == 0 ? 'background-color: #f8fafb' : ''"
            >
              <div class="accordion-body">
                <table class="table tablegy-3 gs-7 align-top">
                  <thead>
                    <tr
                      class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                    >
                      <th>Codice fiscale</th>
                      <th>Cognome</th>
                      <th>Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(partecipante, index) in data.partecipanti"
                      :key="index"
                    >
                      <td>{{ partecipante.codiceFiscale }}</td>
                      <td>{{ partecipante.cognome }}</td>
                      <td>{{ partecipante.nome }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class=""></div>
              </div>
            </div>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
  <ModalErroriRaas
    :id_societa="selectedIdErrorModal"
    link="RAAS_ERR_FORMAZIONE"
  />
</template>

<script>
import Datatable from "../../kt-datatable/KTDatatableSottosezione.vue";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";

import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { useAddRaas } from "@/composables/addRaas";
import ModalErroriRaas from "@/components/components-fit/raas/ModalErroriRaas.vue";
import { getItTime } from "@/requests/raasAddRichiesta";
import { useFormatDate } from "@/composables/formatDate";

export default {
  name: "TableFormazioneEconomato",
  components: { Datatable, Loading, ModalErroriRaas },
  emits: ["getRaasList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const tableHeader = ref([
      /* {
        key: "checkbox",
        sortable: false,
      }, */
      {
        name: "Livello",
        key: "livelloCompetizione",
        sortable: false,
      },
      {
        name: "Identificativo Evento",
        key: "codice",
        sortable: false,
      },
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Luogo",
        key: "luogo",
        sortable: false,
      },
      {
        name: "Data inizio",
        key: "data_inizio",
        align: "center",
      },
      {
        name: "Data fine",
        key: "data_fine",
        align: "center",
      },
      {
        name: "N. partecipanti",
        key: "n_partecipanti",
        align: "center",
        sortable: false,
      },

      /*  {
        name: "Ultimo Invio",
        key: "ultimo_invio",
      },
      {
        name: "Stato",
        key: "stato",
      },
      {
        key: "note",
        sortable: false,
      }, */
    ]);
    const tableHeader2 = ref([
      {
        name: "Partecipanti",
        key: "partecipanti",
      },
    ]);

    const store = useStore();

    const setFetchRowsFormazione = (e) => {
      store.commit("setFetchRowsFormazioneRaas", e);
      emit("getRaasList");
    };
    const setCurrentPageFormazione = (page) => {
      store.commit("setCurrentPageFormazioneRaas", page);
      emit("getRaasList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnFormazioneRaas", columnName);
      store.commit("setSortOrderFormazioneRaas", order);
      emit("getRaasList");
    };

    const isLoading = ref(false);
    const checkAll = ref(false);

    const {
      sendRaasSelezionati,
      sendRaasTutti,
      refreshContent,
      isSendingRaas,
    } = useAddRaas("raas_formazione_list", 1);

    watch(refreshContent, () => {
      emit("getRaasList");
    });

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedraas_formazione_list")
    );

    watch(loaded, () => {
      checkAll.value = false;
    });

    const selectedIdErrorModal = ref(null);

    return {
      getItTime,
      selectedIdErrorModal,
      isSendingRaas,
      sendRaasSelezionati,
      sendRaasTutti,
      checkAll,
      isLoading,
      tableHeader,
      tableHeader2,
      setFetchRowsFormazione,
      isEnabled,
      setCurrentPageFormazione,
      setSortOrderColumn,

      results: computed(() =>
        store.getters.getStateFromName("resultsraas_formazione_list")
      ),
      loaded,
      record: computed(() =>
        store.getters.getStateFromName("recordraas_formazione_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusraas_formazione_list")
      ),
      currentPage: computed(() => store.getters.currentPage_raas_formazione),
      rowsToSkip: computed(() => store.getters.rowtoskip_raas_formazione),
      fetchRows: computed(() => store.getters.fetchrows_raas_formazione),
      sortColumn: computed(() => store.getters.sortcolumn_raas_formazione),
      sortOrder: computed(() => store.getters.sortorder_raas_formazione),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      useFormatDate,
    };
  },
};
</script>

<style scoped></style>
